export default {
    USERS: {
        FETCH_ALL: 'user/list',
        SAVE: 'user/save',
        UPDATE: 'user/update',
        SEARCH_BY_USERNAME: 'user/search/byUsername'
    },
    AUTH: {
        LOGIN: 'login',
        PARTICIPATION: 'participation',
        FORGOT_PASSWORD: 'forgot-password',
        CHANGE_PASSWORD: 'user/changePassword',
        RESET_PASSWORD: 'reset-password',
        REFRESH_TOKEN: 'auth/refreshToken',
        SIGN_UP: 'user/signUp',
        UPDATE_DISPLAYNAME: 'user/update/displayName'
    },
    CUSTOMERS: {
        FETCH_ALL: 'customer/list',
        REGISTER: 'player/signup',
        UPDATE: 'player/update',
        RESET_PASSWORD: 'player/resetPassword'
    },
    TEAM: {
        CREATE: 'team/create',
        LIST: 'team/list',
        LIST_BY_COMPETITION: 'team/list/competition',
        FETCH_BY_ID: 'team/id'
    },
    ADMIN: {
        EMAIL: 'game/sendEmail'
    },
    COMPETITION: {
        CREATE: 'competition/create',
        UPDATE: 'competition/update',
        LIST: 'competition/list',
        PREDICTIONS_OPEN: 'competition/predictionsOpen',
        STATUS: 'competition/status',
        STANDINGS: 'competition/standings'
    },
    CLUB: {
        LIST: 'club/list',
        SEARCH: 'club/search',
        SEARCH_BY_COUNTY: 'club/search/county',
        UPDATE_USERS_CLUB: 'user/update/club'
    },
    FIXTURE: {
        CREATE: 'fixture/create',
        LIST: 'fixture/list',
        LIST_FOR_TEAM: 'fixture/list/team',
        IN_PLAY: 'fixture/inPlay',
        CURRENT: 'fixture/current',
        UPDATE: 'fixture/update'
    },
    RANKINGS: {
        HURLING: 'elo/hurling/current',
        TEAM: 'elo/hurling/team'
    },
    RESULT: {
        HEAD_TO_HEAD: 'result/team/headToHead',
        OVERALL: 'result/team',
        LIST_TEAMS: 'result/team/list',
        FINALS: 'result/team/finals',
        ALL_FINALS: 'result/finals'
    },
    LEAGUE: {
        PERSONALLIST: 'league/personal/list',
        PARTICIPANTS: 'league/personal/participants',
        STATE: 'league/current',
        LEAVE: 'league/leave',
        GAMEWEEK: 'league/gameweek',
        SNAPSHOT: 'league/snapshot',
        GAMEWEEK_SNAPSHOT: 'league/snapshot/gameweek',
        CREATE_PRIVATE: 'league/create/private',
        REGISTER_USER: 'league/register/user',
        SEARCH_BY_LEAGUE_NAME: 'league/search',
        EXISTS_BY_LEAGUE_NAME: 'league/unique'
    },
    SELECTION: {
        CONFIRM: 'game/prediction'
    },
    GAME: {
        WINNING_MARGINS: 'game/margins',
        EMAIL_RECIPIENTS: 'game/emailRecipents',
        PREDICTIONS: 'game/predictions',
        PAST_PREDICTIONS: 'game/past/predictions',
        END_WEEK: 'game/endGameWeek',
        GAMEWEEK_RESULT: 'game/gameweekResult'
    },
    STATISTICS: {
        TEAM_TOTAL_SCORE: '/score/team/totals',
        PLAYER_TOTAL_SCORE: '/score/player/totals',
        TEAM_SCORERS: '/score/team'
    },
    WINNERS: {
        LIST: '/winner/competition/list',
        LIST_FOR_TEAM: '/winner/competition/team',
        LIST_BY_TEAM: '/winner/competition/teams'
    }
}