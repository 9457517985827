export default function redirectToCheckout(url) {
    //fetch('http://localhost:8080/create-checkout-session')
    fetch(`${url}/create-checkout-session`)
        .then(response => response.json())
        .then(data => {
            const { sessionId } = data;
            const stripe = Stripe('pk_test_51MWiWlJgqpX8vKEDYXRY84YrYYRiBVKA2EMMNX3hVpeVx1SW46SEmwdljU3Ba6WGzzcdGTVIzPXARrlPl3Em7wgN00GK5xMpaX'); 
            return stripe.redirectToCheckout({ sessionId });
        })
        .catch(error => {
            console.error('Error during redirect to checkout:', error);
            // Optionally, handle errors (e.g., show an error message to the user)
        });
}